import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img1 from '../../assets/images/opening-ceremony/1.jpg'
import img2 from '../../assets/images/opening-ceremony/2.jpg'
import img3 from '../../assets/images/opening-ceremony/3.jpg'
import img4 from '../../assets/images/opening-ceremony/4.jpg'
import img5 from '../../assets/images/opening-ceremony/5.jpg'
import img6 from '../../assets/images/opening-ceremony/6.jpg'
import img7 from '../../assets/images/opening-ceremony/7.jpg'
import img8 from '../../assets/images/opening-ceremony/8.jpg'
import img9 from '../../assets/images/opening-ceremony/9.jpg'
import img10 from '../../assets/images/opening-ceremony/10.jpg'
import img11 from '../../assets/images/opening-ceremony/11.jpg'
import img12 from '../../assets/images/opening-ceremony/12.jpg'
import img13 from '../../assets/images/opening-ceremony/13.JPG'
import img14 from '../../assets/images/opening-ceremony/14.JPG'
import img15 from '../../assets/images/opening-ceremony/15.JPG'


import img16 from '../../assets/images/opening-ceremony/2N7A9161.jpg' 
import img17 from '../../assets/images/opening-ceremony/2N7A9159.jpg' 
import img18 from '../../assets/images/opening-ceremony/2N7A9160.jpg' 
import img19 from '../../assets/images/opening-ceremony/GH170584.jpg' 
import img20 from '../../assets/images/opening-ceremony/GH170452.jpg' 
import img21 from '../../assets/images/opening-ceremony/GH189828.jpg' 
import img22 from '../../assets/images/opening-ceremony/GH170409.jpg' 
import img23 from '../../assets/images/opening-ceremony/2N7A9226.jpg'
import img24 from '../../assets/images/opening-ceremony/2N7A9181.jpg' 
import img25 from '../../assets/images/opening-ceremony/2N7A9249.jpg' 
import img25Thumbnail from '../../assets/images/opening-ceremony/2N7A9249-thumbnail.png' 





const OpeningCeremonyPage = () => {
    

    return (
        <div className="container">
            <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginTop: '50px' }}>
            التكريم
            </h1>

            <LightGallery
                speed={500}
                plugins={[lgZoom]}
                mobileSettings={{ download: true }}
            >

                    <a  href={img16}>
                        <LazyLoadImage src={img16} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img17}>
                        <LazyLoadImage src={img17} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img18}>
                        <LazyLoadImage src={img18} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img19}>
                        <LazyLoadImage src={img19} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                   
                    <a  href={img20}>
                        <LazyLoadImage src={img20} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img21}>
                        <LazyLoadImage src={img21} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img22}>
                        <LazyLoadImage src={img22} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img23}>
                        <LazyLoadImage src={img23} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img24}>
                        <LazyLoadImage src={img24} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img25}>
                        <LazyLoadImage src={img25Thumbnail} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>



                {/* new images */}

                    <a  href={img13}>
                        <LazyLoadImage src={img13} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img14}>
                        <LazyLoadImage src={img14} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img15}>
                        <LazyLoadImage src={img15} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img7}>
                        <LazyLoadImage src={img7} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>


                    <a  href={img8}>
                        <LazyLoadImage src={img8} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    
                    {/* 6 */}
                    {/* <a  href={img9}>
                        <LazyLoadImage src={img9} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a> */}

                    {/* 7 */}
                    {/* <a  href={img10}>
                        <LazyLoadImage src={img10} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a> */}
                    
                    <a  href={img11}>
                        <LazyLoadImage src={img11} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    <a  href={img12}>
                        <LazyLoadImage src={img12} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>



                {/* old pictures */}

                    <a  href={img1}>
                        <LazyLoadImage src={img1} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                    <a  href={img2}>
                        <LazyLoadImage src={img2} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    {/* 12 */}
                    {/* <a  href={img3}>
                        <LazyLoadImage src={img3} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a> */}
                    
                    <a  href={img4}>
                        <LazyLoadImage src={img4} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>

                    {/* 13 */}
                    {/* <a  href={img5}>
                        <LazyLoadImage src={img5} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a> */}
                    
                    <a  href={img6}>
                        <LazyLoadImage src={img6} style={{ 'padding': '10px', 'width': '400px' }} alt="Leadership Workshop" />
                    </a>
                  
            </LightGallery>

        </div>
    );
};

export default OpeningCeremonyPage;
