import React from "react";

import users from "../../assets/images/trainings/users.png";
import target from "../../assets/images/trainings/target.png";
import file from "../../assets/images/trainings/file.png";
import checklist from "../../assets/images/trainings/checklist.png";
import medal from "../../assets/images/trainings/medal.png";

const Slide4 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#CEDE62",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">الفئة المستهدفة</h4>
              <img
                style={{ width: "30px", marginLeft: "10px" }}
                src={target}
                alt=""
              />
            </div>

            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>موظفي الشركات المقدمة لخدمات تخص القطاع الترفيهي</li>
                <li>الافراد الراغبين في بدء نشاط تجاري في قطاع الترفيه</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="col-lg-6 col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#CEDE62",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">نبذة عن البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={file}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3" dir="rtl">
              أطلقت الهيئة العامة للترفيه برنامج "الدورات تطويرية" تحت مبادرة صناع السعادة
              وذلك من خلال تقديم ورش عمل تطويرية وتأهيلية تواكب رؤية المملكة 2030، لتعزيز استدامة القطاع الترفيهي في المملكة، في تنظيم وإدارة الفعاليات والأنشطة الترفيهية بجميع اختصاصاتها الهامة وذلك لتفعيل القطاع وتمكين الأفراد
              حيث تهدف إلى تأهيل وتدريب أكثر من 3600 متدرب على مستوى المدن الرئيسية بالمملكة، وتمكينهم من شغل الوظائف في قطاع الترفيه بكفاءة واحترافية عالية.

            </p>
          </div>
        </div>

        <div className="col-12 mt-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#CEDE62",
              color: "white",
              height: "100%",
            }}
          >
             <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">مدة البرنامج</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={users}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">مدة البرنامج من يوم الى خمسة أيام بحسب عدد أيام الدورة ويلزم التفرغ التام في أوقات الدورة</p>
          </div>
        </div>

        <div className="col-12 my-3">
          <div
            className="p-3"
            style={{
              backgroundColor: "#CEDE62",
              color: "white",
              height: "100%",
            }}
          >
            <div className="title d-flex align-items-center justify-content-end">
              <h4 className="d-inline mb-0 fw-bold">معايير القبول</h4>
              <img
                style={{ width: "20px", marginLeft: "10px" }}
                src={checklist}
                alt=""
              />
            </div>
            <p className="text-white fw-normal py-3">
              <ul style={{ direction: "rtl", listStylePosition: "inside" }}>
                <li>سعودي الجنسية</li>
                <li>مهتم في مجال الترفيه</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="row" style={{ 'width': 'max-content', 'padding-bottom': '20px' }}>
          
          <div className="col">
            <a className="text-white" href="https://www.geacourses.com/" target="_blank">
              <button className="text-white" style={{ 'backgroundColor': 'rgb(206, 222, 98)', 'padding': '10px 30px', 'border': 'none', 'color': 'white', 'fontSize': '18px' }}>
                سجل الان
              </button>
            </a>
          </div>

        </div>

    </div>
  );
};

export default Slide4;

// #F5C46B
