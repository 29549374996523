import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ReactGA from "react-ga";

import ScrollToTop from "./utils/ScrollToTop";

import "./App.css";
import "../src/assets/sass/landing.scss";
import "../src/assets/sass/initiative.scss";
import "../src/assets/sass/objectives.scss";
import "../src/assets/sass/trainings.scss";
import "../src/assets/sass/gallery-questions.scss";
import "../src/assets/sass/footer.scss";
import "../src/assets/sass/previous-work.scss";
import "../src/assets/sass/courses.scss";
import "../src/assets/sass/forms.scss";
import "../src/assets/sass/animations.scss";
import "../src/assets/sass/contact.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import "../src/assets/sass/styles.scss";


import LandingPage from "./pages/landing.page";
import Workshops from "./pages/gallery-pages/workshops";
import OpeningCeremonyPage from "./pages/gallery-pages/opening-ceremony-page";
import FieldVisits from "./pages/gallery-pages/field-visits";

import { Helmet } from 'react-helmet';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 2 * 60 * 1000, // 2 minutes
      cacheTime: 2 * 60 * 1000, // 2 minutes
    },
  },
});

const TRACKING_ID = "G-GDFRDWH5ZP";
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/workshops" element={<Workshops />} />
            <Route path="/opening-ceremony" element={<OpeningCeremonyPage />} />
            <Route path="/visits" element={<FieldVisits />} />

          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;
