import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img2 from '../../assets/images/leadership/leadership-2.jpg'
import img5 from '../../assets/images/leadership/leadership-5.jpg'
import img9 from '../../assets/images/leadership/9.jpg'


import img10 from '../../assets/images/fellowship/3.jpg'
import img14 from '../../assets/images/fellowship/7.jpg'

import img16 from '../../assets/images/fellowship/9.jpg'
import img17 from '../../assets/images/fellowship/10.jpg'
import img18 from '../../assets/images/fellowship/11.jpg'
import img20 from '../../assets/images/fellowship/13.jpg'
import img21 from '../../assets/images/fellowship/14.jpg'

import img22 from '../../assets/images/fellowship/15.png'
import img23 from '../../assets/images/fellowship/16.png'
import img24 from '../../assets/images/fellowship/17.png'
import img25 from '../../assets/images/fellowship/18.png'
import img26 from '../../assets/images/fellowship/19.png'


const Workshops = () => {
    

  
    return (
        <div className="container">
            <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginTop: '50px' }}>
            ورش العمل
            </h1>

            <LightGallery
                speed={500}
                plugins={[lgZoom]}
                mobileSettings={{ download: true }}>
                    
                    <a href={img22}>
                        <LazyLoadImage src={img22} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a href={img23}>
                        <LazyLoadImage src={img23} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a href={img24}>
                        <LazyLoadImage src={img24} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a href={img25}>
                        <LazyLoadImage src={img25} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a href={img26}>
                        <LazyLoadImage src={img26} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>

                    {/* new photos above 22 sep */}
                    <a  href={img16}>
                        <LazyLoadImage src={img16} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a  href={img17}>
                        <LazyLoadImage src={img17} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a  href={img18}>
                        <LazyLoadImage src={img18} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>

                    {/* 4 */}
                    {/* <a  href={img19}>
                        <LazyLoadImage src={img19} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                    <a  href={img20}>
                        <LazyLoadImage src={img20} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a  href={img21}>
                        <LazyLoadImage src={img21} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>



                {/* Old pictures */}

                    {/* 7 */}
                    {/* <a  href={img7}>
                        <LazyLoadImage src={img7} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                    
                    <a  href={img2}>
                        <LazyLoadImage src={img2} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>

                    {/* 9 */}
                    {/* <a  href={img8}>
                        <LazyLoadImage src={img8} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                    <a  href={img9}>
                        <LazyLoadImage src={img9} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    <a  href={img5}>
                        <LazyLoadImage src={img5} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    {/* 12 */}
                    {/* <a  href={img6}>
                        <LazyLoadImage src={img6} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                  

                    <a  href={img10}>
                        <LazyLoadImage src={img10} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>
                    {/* 14 */}
                    {/* <a  href={img11}>
                        <LazyLoadImage src={img11} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                    {/* <a  href={img12}>
                        <LazyLoadImage src={img12} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}

                    {/* 15 */}
                    {/* <a  href={img13}>
                        <LazyLoadImage src={img13} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                    <a  href={img14}>
                        <LazyLoadImage src={img14} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a>

                    {/* 17 */}
                    {/* <a  href={img15}>
                        <LazyLoadImage src={img15} style={{ 'padding': '10px', 'width': '400px' }} alt="" />
                    </a> */}
                  
            </LightGallery>

        </div>
    );
};

export default Workshops;
