import React, { useEffect } from "react";
import LandingComponent from "../components/landing.component";
import ObjectivesComponent from "../components/objectives.component";
import InitiativeComponent from "../components/initiative.component";
import TrainingsComponent from "../components/trainings.component";
import GalleryQuestions from "../components/gallery-questions-v2.component";
import ContactUs from "../components/contact-form.compnent";
import Footer from "../components/footer.component";
import HoverEffect from "../components/animation.component";

import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';

const LandingPage = () => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);
    return(
        <>
         <Helmet>
        <title>صناع السعادة</title>
                <meta name="description" content="صناع السعادة" />
            </Helmet>
        <HoverEffect/>
        <LandingComponent/>
        <InitiativeComponent/>
        <ObjectivesComponent/>
        <TrainingsComponent/>
        <GalleryQuestions/>
        <ContactUs/>
        <Footer/>
        </>
    )
}


export default LandingPage;