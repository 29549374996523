import React, { useEffect, useState } from "react";
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import img1 from '../../assets/images/visits/1.png'
import img3 from '../../assets/images/visits/3.png'
import img4 from '../../assets/images/visits/4.png'


import img11 from '../../assets/images/visits/5.jpg'
import img13 from '../../assets/images/visits/7.jpg'
import img15 from '../../assets/images/visits/9.jpeg'
import img16 from '../../assets/images/visits/10.jpg'
import img18 from '../../assets/images/visits/12.jpg'
import img19 from '../../assets/images/visits/13.jpg'
import img20 from '../../assets/images/visits/14.jpg'


import img21 from "../../assets/images/visits/Takamol_Orlando_Day_5-33.png"
import img22 from "../../assets/images/visits/Takamol_Orlando_Day_2-7.png"
import img23 from "../../assets/images/visits/Takamol_Orlando_Day_2-94.png"
import img24 from "../../assets/images/visits/Takamol_Orlando_Day_3-111.png"
import img25 from "../../assets/images/visits/Takamol_Orlando_Day_3-72.png"
import img26 from "../../assets/images/visits/Orlando_Group_3.png"
import img27 from "../../assets/images/visits/PAT07943.png"
import img28 from "../../assets/images/visits/PAT08952.png"
import img29 from "../../assets/images/visits/PAT09070.png"
import img30 from "../../assets/images/visits/PAT00374.png"
import img31 from "../../assets/images/visits/PAT08318.png"
import img32 from "../../assets/images/visits/PAT00199.jpg"
import img33 from "../../assets/images/visits/008-RG2_7848.png"
import img34 from "../../assets/images/visits/077-RG2_8169.png"
import img35 from "../../assets/images/visits/134-RG2_8589.png"
import img36 from "../../assets/images/visits/JPL_8979.png"
import img37 from "../../assets/images/visits/_JPL2182.png"
import img38 from "../../assets/images/visits/JPL_3235.png"
import img39 from "../../assets/images/visits/_JPL2118.png"
 

const FieldVisits = () => {
    

    return (
        <div className="container">
            <h1 style={{ fontFamily: "thesans-plain, sans-serif", fontWeight: 'bold', marginTop: '50px' }}>
            الزيارات الميدانية
            </h1>

            <LightGallery
                speed={500}
                plugins={[lgZoom]}
                mobileSettings={{ download: true }}>

                    <a href={img21}>
                        <LazyLoadImage src={img21} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img22}>
                        <LazyLoadImage src={img22} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img23}>
                        <LazyLoadImage src={img23} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img24}>
                        <LazyLoadImage src={img24} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img25}>
                        <LazyLoadImage src={img25} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img26}>
                        <LazyLoadImage src={img26} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img27}>
                        <LazyLoadImage src={img27} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img28}>
                        <LazyLoadImage src={img28} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img29}>
                        <LazyLoadImage src={img29} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img30}>
                        <LazyLoadImage src={img30} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img31}>
                        <LazyLoadImage src={img31} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img32}>
                        <LazyLoadImage src={img32} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img33}>
                        <LazyLoadImage src={img33} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img34}>
                        <LazyLoadImage src={img34} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img35}>
                        <LazyLoadImage src={img35} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img36}>
                        <LazyLoadImage src={img36} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img37}>
                        <LazyLoadImage src={img37} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img38}>
                        <LazyLoadImage src={img38} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                  
                    <a href={img39}>
                        <LazyLoadImage src={img39} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                   

                    {/* new photos above 22 sep */}

                    <a href={img11}>
                        <LazyLoadImage src={img11} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                   

                    <a  href={img13}>
                        <LazyLoadImage src={img13} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                

                    <a  href={img15}>
                        <LazyLoadImage src={img15} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    
                    <a  href={img16}>
                        <LazyLoadImage src={img16} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                 
                    <a  href={img18}>
                        <LazyLoadImage src={img18} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img19}>
                        <LazyLoadImage src={img19} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                   
                    {/* old images */}
                    <a  href={img1}>
                        <LazyLoadImage src={img1} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>

                    <a  href={img3}>
                        <LazyLoadImage src={img3} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a  href={img4}>
                        <LazyLoadImage src={img4} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>
                    <a  href={img20}>
                        <LazyLoadImage src={img20} style={{ 'padding': '10px', 'width': '400px' }} alt="Fellowship Workshop" />
                    </a>


                  
            </LightGallery>

        </div>
    );
};

export default FieldVisits;
